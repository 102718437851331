var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"WidgetTop-Section"},[_c('b-row',[_c('b-col',{attrs:{"lg":"2","sm":"2"}},[_c('div',{staticClass:"outerBorderImage"},[_c('div',{staticClass:"outerSponImage"},[_c('img',{staticClass:"sponsorImage",attrs:{"src":_vm.TourLogo}})])])]),_c('b-col',{attrs:{"lg":"10","sm":"10"}},[_c('div',{staticClass:"contentBlock"},[_c('span',{staticClass:"titleRound"},[(_vm.currentTitle == 'Live Scoring Monitor')?[_vm._v(" Latest Scores ")]:[_vm._v(" "+_vm._s(_vm.currentTitle)+" ")]],2),_c('span',{staticClass:"tourName"},[_vm._v(_vm._s(this.TourName))])])])],1)],1),_c('ReportTable',{attrs:{"data":_vm.data,"title":_vm.title,"home":true,"updown":_vm.updown,"config":_vm.config,"team":_vm.teamEvent,"match":_vm.match}}),_c('b-nav',{staticClass:"fullListings",attrs:{"small":""}},[_c('b-container',{staticStyle:{"display":"contents"}},[(_vm.title === 'Tournament Entries')?_c('b-nav-item',{staticClass:"Flisting-btn right",attrs:{"to":{
          name: 'reports',
          query: {
            url:
              _vm.config.VUE_APP_PLAYER_HOST +
              '/tic/tmentry.cgi?tourn=' +
              _vm.courseCode +
              '~season=' +
              _vm.Year +
              '~alphaorder~',
            id: _vm.Year,
            code: _vm.courseCode,
            title: 'Tournament Entries',
          },
        }}},[_c('span',{staticClass:"black"}),_vm._v(" See Full Listing ")]):_vm._e(),(_vm.title === 'Final Result')?_c('b-nav-item',{staticClass:"Flisting-btn right",attrs:{"to":{
          name: 'reports',
          query: {
            url:
              _vm.config.VUE_APP_PLAYER_HOST +
              '/tic/tmresult.cgi?tourn=' +
              _vm.courseCode +
              '~season=' +
              _vm.Year +
              'result=PF~&',
            id: _vm.Year,
            code: _vm.courseCode,
            title: 'Final Result',
          },
        }}},[_c('span',{staticClass:"black"}),_vm._v(" See Full Listing ")]):_vm._e(),(_vm.currentReport.includes('draw'))?_c('b-nav-item',{staticClass:"left drawListing-btn",attrs:{"to":{
          name: 'reports',
          query: {
            url: _vm.reportURL,
            id: _vm.Year,
            code: _vm.courseCode,
            title: 'Draw',
          },
        }}},[_c('span',{staticClass:"black"}),_vm._v(" Draw ")]):_vm._e(),(_vm.currentReport.includes('score'))?_c('b-nav-item',{staticClass:"right four Flisting-btn",attrs:{"to":{
          name: 'reports',
          query: {
            url: _vm.reportURL,
            id: _vm.Year,
            code: _vm.courseCode,
            title: _vm.title,
          },
        }}},[_c('span',{staticClass:"black"}),_vm._v(" See Full Listing ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="tableHome" v-bind:style="{ 'background-image': 'url(' + 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/courses/' + Year + '/' + imageCode + '/' + bkImage + ')' }">
   <b-container>
    <b-row>
      <b-col>
        <b-row class="SponsorLogoText">
          <b-col lg="5">
            <div class="outerBorderImage">
              <div class="outerSponImage">
                <img class="sponsorImage" :src="TourLogo">
              </div>
            </div>
          </b-col>
          <b-col>
            <h2 class="TournDates">{{tournDates}}</h2>
            <h1 class="TournName">{{TourName}}</h1>
            <b-link :to="'/tournament/' + courseCode + '/' + Year" class="allNewsLink">
              <button class="Allbuttons">
               See Full Listing
              </button>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-tabs>
          <HomeTabOne
            :Year="Year"
            :courseCode="courseCode"
            :TourName="TourName"
            :TourLogo="TourLogo"
            :currentReport="currentReport"
            :currentTitle='currentTitle'
            :reportURL="reportURL"
            :live='live'
            :config="config"
            :match='match'
            :roundsPLayed="roundsPLayed"
          />
        </b-tabs>
      </b-col>
    </b-row>
   </b-container>
  </div>
</template> 

<script>
import HomeTabOne from "@/components/homeTabOne.vue";

export default {
  name: "tableHome",
  props: ["Year", "tournDates",  "imageCode",  "courseCode", "TourName", "TourLogo", "bkImage", "currentReport", "reportURL", 'live', 'config','match', 'currentTitle','roundsPLayed'],
  components: {
    HomeTabOne
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.outerBorderImage {
  border-radius: 50%;
  height: 258px;
  width: 258px;
  /* border: 19px solid #ff0000; */
  opacity: 0.9;
  margin-top: -32px;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/White_Circle.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.outerSponImage {
  /* background-color: #fff; */
  border-radius: 50%;
  /* height: 230px;
  width: 230px; */
  height: 232px;
  width: 232px;
}
img.sponsorImage {
  width: 120px;
  max-width: 120px;
  position: relative;
  top: 70px;
  left: 29%;
  overflow: hidden;
}
.SponsorLogoText {
  margin-top: 10em;
}
.Allbuttons {
  /* float: right; */
  width: 244px;
  height: 50px;
  background: transparent;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  /* float: right; */
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
.TournDates {
  font-size: 26px;
  color: #fff;
  font-weight: bold;
  padding-bottom: 20px;
}
.TournName {
  font-size: 42px;
  color: #fff;
  font-weight: bold;
  padding-bottom: 20px;
}
.tabs {
  /* width: auto; */
  /* width: 450px; */
  width: 536px;
  border-radius: 30px;
  /* margin-top: 5em; */
  margin-top: 2em;
  float: right;
  position: relative;
}

::v-deep .nav-link.active {
  color: white !important;
  background-color: #263056 !important;
  width: 100%;
  border-color: #263056 !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
  font-weight: 500;
}

::v-deep li.nav-item {
  width: 100%;
  text-align: center;
}
.tableHome {
  padding-bottom: 2%;
  background-repeat: no-repeat;
  height: 700px;
  background-size: cover;
  margin-bottom: 4em;
  background-position: center;
}
 .tableHome:before {
  content: "";
  position: absolute;
  overflow: hidden;
  /* border-radius: 30px; */
  width: 100%;
  max-height: 700px;
  height: 38em;
  top: 10em;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)); */
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
 }
.mt {
  margin-top: 0 !important;
}
::v-deep .nav-tabs{
  border-bottom: 0e6;
}
::v-deep .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
@media only screen and (max-width: 1199px) {
  .tableHome:before {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 100%;
    max-height: 700px;
    height: 38em;
    top: 7.5em;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (max-width: 990px) {
  .tableHome {
    padding-bottom: 2%;
    background-repeat: no-repeat;
    height: 500px;
    background-size: cover;
    margin-bottom: 40em;
    background-position: center;
  }
  .tableHome:before {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 100%;
    max-height: 520px;
    height: 38em;
    top: 7em;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .tabs {
    width: auto;
    border-radius: 30px;
    /* margin-top: 5em; */
    margin-top: 10em;
    float: none;
    position: relative;
  }
  .outerBorderImage {
    border-radius: 50%;
    height: 190px;
    width: 190px;
    /* border: 19px solid #ff0000; */
    opacity: 0.9;
    margin-top: -88px;
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/White_Circle.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 100px;
  }
  img.sponsorImage {
      width: 120px;
      /* max-width: 120px; */
      position: relative;
      top: 39px;
      left: -9%;
  }
  .TournDates {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    padding-bottom: 20px;
  }
  .TournName {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    padding-bottom: 20px;
  }
  .SponsorLogoText {
    margin-top: 10em;
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
 ::v-deep .nav-link{
    font-size: 1rem;
  }
}
@media only screen and (max-width: 375px) {
  .outerBorderImage {
    border-radius: 50%;
    height: 190px;
    width: 190px;
    /* border: 19px solid #ff0000; */
    opacity: 0.9;
    margin-top: -88px;
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/White_Circle.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 75px;
  }
}
</style>

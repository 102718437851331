<template>
  <div class="home">
      <div id="table">
        <TableHome
          :Year="season"
          :courseCode="course"
          :TourName="tm_params.short_name"
          :TourLogo="tourlogo"
          :bkImage="bkImage"
          :currentReport="reportTitle"
          :reportURL="currentReport"
          :live="tm_params.live_scoring"
          :config="config"
          :match="tm_params.match_play"
          :currentTitle='currentTitle'
          :roundsPLayed='roundsPLayed'
          :imageCode='imageCode'
          :tournDates="tournDates"
        />
      </div>
      <div id="slider">
        <Slider :Year="tm_params.tm_params.season_code" :config="config" :DEFIMAGESAMS3="DEFIMAGESAMS3"/>
      </div>
      <div class="oonSliderBk">
        <OOMslider :config="config" :Year="season"/>
      </div>
      <div id="posts">
        <b-container>
          <Posts :config="config"/>
        </b-container>
      </div>
      <div class="liveScoreMsg" v-if="tm_params.live_message === 'Y'">
        <h3 class="liveMsg">
          {{ tm_params.live_scoring_mess }}
        </h3>
      </div>
  </div>
</template>

<script>
import TableHome from "@/components/tableHome.vue";
import Slider from "@/components/slider.vue";
import OOMslider from "@/components/oomSlider.vue";
// import TwitterFeed from "@/components/twitterFeed.vue";
// import Instafeed from "@/components/instaFeed.vue";
import Posts from "@/components/posts.vue";
// import HomeReportSelect from "@/components/homeReportSelect.vue";
import apiCall from "@/services/api.js";
export default {
  name: "home",
  props: ["config", "meta_data"],
  components: {
    TableHome,
    Slider,
    OOMslider,
    // TwitterFeed,
    // Instafeed,
    Posts,
    // HomeReportSelect,
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      tm_params: [],
      reports: [],
      course: [],
      season: [],
      roundsPLayed: '',
      imageCode: '',
      tournDates: ''
    };
  },
  metaInfo() {
    var metaD = this.metaSelect[0].yoast_meta
    return {
      title: 'Alps Tour Golf – Rising Golf Stars',
      meta: metaD
    };
  },
  computed: {
    metaSelect: function() {
      return this.meta_data.filter((page) => page.slug === this.$route.name);
    },
    filterReports: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            reports_entry.report_title == 'Round 1 Draw' ||
            reports_entry.report_title.includes("Score") ||
            reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports; 
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_url;
      else return this.filterReports.report_url;
    },
    // currentReport() {
    //   if (Array.isArray(this.reports))
    //       if (this.roundsPLayed === '0') {
    //         return this.reports[this.reports.length - 2].report_url; 
    //       } else {
    //         return this.reports[this.reports.length - 1].report_url;  
    //       }   

    //   else return this.reports.report_url;
    // },
    currentTitle: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_title;
      else return this.filterReports.report_title;
    },
    // currentTitle: function() {
    //   if (Array.isArray(this.reports))
    //       if (this.roundsPLayed === '0') {
    //         return this.reports[this.reports.length - 2].report_title; 
    //       } else {
    //         if (this.tm_params.class_group == 'QSC') {
    //           return this.reports[this.reports.length - 6].report_title;  
    //         } else {
    //           return this.reports[this.reports.length - 1].report_title; 
    //         } 
    //       }   
    //   else return this.reports.report_title;
    // },
    reportTitle: function(url) {
      if (this.tm_params.class_group == 'QSC') {
        if (
          this.currentReport.indexOf("tmentry") !== -1 ||
          this.currentReport.indexOf("tmdraw") !== -1
        ) {
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "qsc" +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-entry-entries.json?randomadd=" +
            new Date().getTime();
        } else {
          if (this.currentReport.indexOf("tmscore") !== -1) {
            var subRep3 = this.currentReport.lastIndexOf("params=") + 7;
            var indNext3 = this.currentReport.indexOf("~", subRep3);
            var repParams1 = this.currentReport.substr(
              subRep3,
              indNext3 - subRep3
            );
            if (repParams1 && repParams1.indexOf(".cgi") < 0) {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "qsc" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-" +
                repParams1 +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "qsc" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
            {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "qsc" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmstats") !== -1) {
            {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "qsc" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmcrsts") !== -1) {
            {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "qsc" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmresult") !== -1) {
            var subRep4 = this.currentReport.lastIndexOf("result=") + 7;
            var indNext6 = this.currentReport.indexOf("~", subRep4);
            var resNum2 = this.currentReport.substr(subRep4, indNext6 - subRep4);
            console.log("resNum === ");
            console.log(resNum2);
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "qsc" +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-result-result-" +
              'PF' +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "qsc" +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-result-result-PF.json" +
              "?randomadd=" +
              new Date().getTime();
          }
        }
      } else {
        if (
          this.currentReport.indexOf("tmentry") !== -1
        ) {
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "alp" +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-entryw-entries.json?randomadd=" +
            new Date().getTime();
        } else if(this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_API +
          "sst" +
          "/cache/" +
          this.apiClass +
          "/" +
          this.season +
          "/" +
          this.season +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
        } else if(this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "alp" +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-" +
            "draw" +
            "-" +
            'R'+drawRnd
            +
            "-draw.json?randomadd=" +
            new Date().getTime();
        } else {
          if (this.currentReport.indexOf("tmscore") !== -1) {
            var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
            var indNext2 = this.currentReport.indexOf("~", subRep2);
            var repParams = this.currentReport.substr(
              subRep2,
              indNext2 - subRep2
            );
            if (repParams && repParams.indexOf(".cgi") < 0) {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "alp" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-" +
                repParams +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "alp" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
            {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "alp" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmstats") !== -1) {
            {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "alp" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmcrsts") !== -1) {
            {
              url =
                this.config.VUE_APP_API +
                this.config.VUE_APP_ID +
                "/cache/" +
                "alp" +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.course +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmresult") !== -1) {
            var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
            var indNext1 = this.currentReport.indexOf("~", subRep1);
            var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
            console.log("resNum === ");
            console.log(resNum1);
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "alp" +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-result-result-" +
              resNum1 +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "alp" +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-result-result-PF.json" +
              "?randomadd=" +
              new Date().getTime();
          }
        } 
      }
      return url;
    },
  },
  methods: {
    qschool: function() {
      apiCall
      .tmParamsBaseqsc()
      .then(({ data }) => {
        this.tm_params = data;
        this.DEFIMAGESAMS3 = data.tm_params.DEFIMAGESAMS3;
        this.roundsPLayed = data.rounds_played;
        console.log("this.roundsPLayed")
        console.log(this.roundsPLayed)
        this.reports = data.reports.reports_entry;
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    }
  },
  created() {
    apiCall
      .tmParamsBase()
      .then(({ data }) => {
        this.tm_params = data;
        this.DEFIMAGESAMS3 = data.tm_params.DEFIMAGESAMS3;
        this.roundsPLayed = data.rounds_played;
        console.log("this.roundsPLayed")
        console.log(this.roundsPLayed)
        if (data.class_group == 'QSC') {
          this.qschool();
          console.log('IN QSC')
          this.reports = data.reports.reports_entry; 
        } else {
          console.log('NOT IN QSC')
          this.reports = data.reports.reports_entry;
        }
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.tourlogo = data.sponsor_logo;
        this.bkImage = data.course_image_file_1;
        this.imageCode = data.course_code;
        this.tournDates = data.dates;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>
<style scoped>
::v-deep div#posts {
  margin-top: 30px;
}
div#slider {
  padding-bottom: 80px;
}
.oonSliderBk {
  background-color: #F8F8F8;
  /* height: 500px; */
}
#twitter {
  padding-top: 50px;
}
#social {
  padding: 2%;
}
#posts {
  padding: 3%;
}
.sponsor {
  max-width: 250px;
  /* max-width: 136px; */
  vertical-align: middle;
  margin-right: auto;
  /* margin-bottom: 10px; */
  margin-bottom: 25px;
  margin-top: -135px !important;
  margin-left: 10% !important;
}
.liveScoreMsg {
  position: fixed;
  bottom: 0px;
  z-index: 10000;
  min-height: 80px;
  width: 100%;
  padding: 15px;
  background-color: #263056;
  text-align: center;
}
.liveMsg {
  text-align: center;
  color: #ffffff;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  font-size: 24px;
}
@media only screen and (max-width: 980px) {
  .sponsor {
    display: none;
  }
  div#slider {
    padding-bottom: 80px;
    margin-top: 11em;
    display: inline-block;
  }
}
@media only screen and (max-width: 480px) {
  .mobileSpace {
    margin-top: 50px;
  }
  #twitter {
    flex-basis: auto;
    padding-top: 0px;
  }
  #reports {
    flex-basis: auto;
  }
  #rt {
    flex-basis: auto;
  }
}
</style>
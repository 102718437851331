<!-- Nav Menu -->

<template>
  <div id="app" style="position:relative;">
    <Menu :config="env"/>
    <transition name="fade" appear> <router-view :key="$route.fullPath" :config="env" :meta_data="wp_meta_page"/></transition>
    <Footer :config="env"/>
  </div>
</template>

<style>
@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        /* max-width: 1640px!important; */
        max-width: 1500px!important;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&display=swap');
a {
  color: #337ab7!important;
  text-decoration: none;
  background-color: transparent;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-size: 14pt;
  font-family: "Gantari", serif;
  font-optical-sizing: auto;
  /* font-weight: 300; */
  font-style: normal;
}
li.nav-item::marker {
  color: transparent;
}

@media only screen and (max-width: 480px) {
  #app {
    font-size: 10pt;
  }
}
</style>

<script>
import Menu from "@/components/menu.vue";
import Footer from "@/components/footer.vue";
import axios from 'axios'
export default {
  components: {
    Menu,
    Footer
  },
  data(){
    return{

      env:[],
      wp_meta_page:[]
    }
  },
  mounted() {
    this.env = process.env,
        axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL  + "wp/v2/pages?per_page=30"
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
          (this.wp_meta_page= response.data);
      });
  }
};
</script>

<template>
  <div class="footer">
    <div class="footerTopColour">
      <b-container>
        <h2 class="ocsSponTitle">Our Sponsors & Partners</h2>
        <b-row :class="logoRow" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in footerData" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <b-row :class="logoRowMobile" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in footerData.slice(0, 3)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <b-row :class="logoRowMobile" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in footerData.slice(3, 6)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <b-row :class="logoRowMobile" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in footerData.slice(6, 9)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <b-row :class="logoRowMobile" class="sponsorsBackGround">
          <b-col cols='4' v-for="(logos, index) in footerData.slice(9, 12)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
      </b-container>

      <!-- cosponsors section -->
      <b-container v-if="cosponsorss.length">
        <h2 class="ocsSponTitle cosponsors">Co-Sponsors</h2>
        <b-row :class="logoRow" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in cosponsorss.slice(0,9)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <b-row :class="logoRow" class="sponsorsBackGround logoNoPad">
          <b-col v-for="(logos, index) in cosponsorss.slice(10,18)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <!-- <b-row :class="logoRowMobile" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in cosponsors.slice(0, 2)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row>
        <b-row :class="logoRowMobile" class="sponsorsBackGround">
          <b-col v-for="(logos, index) in cosponsors.slice(2, 4)" :key="index">
            <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
              <b-img :class="logoImg" :src="logos.acf.color_image" />
            </a>
          </b-col>
        </b-row> -->
      </b-container>

    </div>
    <div class="footerBottomColour">
      <b-container>
      <b-row class="ocs showDesk">
        <b-col>
            <b-img
              :class="ocsLogo"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/ALPS-TOUR-NEGATIVE_Logo.png"/>
            
            <span class="standardText-size">© {{new Date().getFullYear()}} Alps Tour Golf </span>
        </b-col>
        <b-col>
          <p class='tmark'>
            Site by OCS Software
          </p>
        </b-col>
      </b-row>
      <b-row class="ocs showMob">
        <b-col>
            <b-img
              :class="ocsLogo"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/ALPS-TOUR-NEGATIVE_Logo.png"/>
        </b-col>
        <b-col cols="12" lg='12'>
            <span class="standardText-size">© {{new Date().getFullYear()}} Alps Tour Golf </span>
        </b-col>
        <b-col cols="12" lg='12'>
          <p class='tmarkMob'>
            Site by OCS Software
          </p>
        </b-col>
      </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Footer",
  props:['config'],
  data() {
    return {
      footerData: [],
      cosponsorss: [],
      logoImg: "logoImg",
      ocsLogo: "ocsLogo",
      logoRow: "logoRow",
      logoRowMobile: "logoRowMobile", 
    };
  },
  created() {
    const json = "wp/v2/sponsors?randomadd=" +
            new Date().getTime();
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.footerData = data;
        this.cosponsors();
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  methods: {
    cosponsors: function() {
      const cojson = "wp/v2/cosponsors?per_page=18&randomadd=" +
              new Date().getTime();
      apiCall
        .wpInfo(cojson)
        .then(({ data }) => {
          this.cosponsorss = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  },
};
</script>

<style scoped>
.row.sponsorsBackGround.logoNoPad.logoRow {
    padding-top: 0;
    margin-top: -50px;
}
h2.ocsSponTitle.cosponsors {
  padding-top: 0px;
}
.standardText-size {
  font-size: 16px;
  padding-left: 40px;
}
.footerTopColour {
  background-color: #F8F8F8;
}
.sponsorsBackGround {
  background-color: #F8F8F8;
}
.ocsSponTitle {
  background-color: #F8F8F8;
  color: #263056;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
  padding-top: 60px;
}
.footer {
  /* background-color: #263056;  */
  color: white;
  padding-top: 2%;
  /* padding-left: 2%; */
}

.logoRowMobile {
  display: none;
}

.logoImg {
  /* width: 80%; */
  width: 110px;
  height: 110px;
  mix-blend-mode: multiply;
}

.ocsLogo {
  padding-bottom: 4%;
  width: 199px;
  margin-top: 35px;
}

.logoRow {
  /* padding-top: 4%; */
  padding-top: 2%;
  padding-bottom: 4%;
  text-align: center;
  margin-right: 0;
}
.ocs {
  margin-right: 0;
  background-color: #263056; 
}
.footerBottomColour {
  background-color: #263056;
}
.tmark{
  float: right;
  margin-top: 50px;
  font-size: 16px;
}
.tmarkMob{
  font-size: 16px;
  padding-left: 10px;
}
::v-deep .nav-link {
  color: white !important;
  padding: 0.7rem 1rem;
  font-size: 14px;
}

::v-deep .navbar {
  padding-bottom: 0;
}

::v-deep .navbar-toggler {
  border: 0px;
}

::v-deep .nav-link:hover {
  color: #263056 !important;
  background-color: white;
}

::v-deep .dropdown-item {
  color: white !important;
  background-color: #263056;
}

::v-deep .dropdown-menu {
  color: white !important;
  background-color: #263056;
}

::v-deep .dropdown-item:hover {
  color: #263056 !important;
  background-color: white;
}
@media only screen and (min-width: 1025px) {
  .showMob {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .showDesk {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .ocsLogo {
    width: 30%;
  }
  .row.ocs.showMob {
    text-align: center;
    margin: auto;
  }
  .standardText-size {
    font-size: 16px;
    padding-left: 0px;
  }
  .ocsLogo {
    width: 30% !important;
    margin-top: 15px!important;
  }
}
@media only screen and (max-width: 480px) {
  .logoImg {
    width: 100%;
    height: auto;
  }
  ::v-deep .ocsLogo {
    width: 20%!important;
    margin-top: 40px;
  }
  .logoRow {
    display: none;
  }
  .logoRowMobile {
    display: flex;
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
  .ocs {
    margin-right: 0;
    margin-left: 0;
  }
  #nav{
    display: none;
  }
  .ocsLogo {
  width: 50%;
  }
}
</style>

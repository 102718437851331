<template>
    <div class="paddingSpace" v-if="oomData.oom_home_page == 'B' || oomData.oom_home_page == 'W'">
      <b-container>
        <b-row class="TitleArea">
            <b-col sm="12" lg="6">
                <h1 class="heading extraPad">Alps Tour Golf Order of Merit</h1>
            </b-col>
            <b-col  sm="12" lg="6">
                <b-link to="/stats" class="allNewsLink">
                    <button class="Allbuttons">
                        See Full Rankings
                    </button>
                </b-link>
            </b-col>
        </b-row>

        <carousel-3d :disable3d="true" :space="390" :clickable="false" :controls-visible="true" :startIndex='0'>
            <slide v-for="(slide, i) in slides" :index="i" :key="i" >
              <div class="oomImage" v-bind:style="{ 'background-image': 'url(' + 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' + slide.playerno +'.jpg' + ')' }">
              </div>
              <div class="oomSliderPad">
                <div class="posCenter">
                    <span class="posPosition">{{slide.pos}}</span>
                </div>
                <b-link class="playerProfile" :to="'/playerprofile/' + slide.playerno">
                  <p class="slideName">
                  {{slide.forename}}
                  <br>
                  {{slide.surname}}
                  </p>
                </b-link>
                <b-row> 
                  <b-col>
                    <span class="oomSliderTitle">Nationality</span>
                  </b-col>
                  <b-col>
                    <span class="oomSliderDetails">
                      <img
                      :class="flag"
                      :src="(config.VUE_APP_FLAG_URL + slide.nationality) | lowercase"
                      />
                    </span>
                  </b-col>
                </b-row>
                <hr class="lineBreakLine">
                <b-row>
                  <b-col>
                    <span class="oomSliderTitle">Points</span>
                  </b-col>
                  <b-col>
                    <span class="oomSliderDetails">{{slide.oom_value}}</span>
                  </b-col>
                </b-row>
                <hr class="lineBreakLine">
                <b-row>
                  <b-col>
                    <span class="oomSliderTitle">Tournament</span>
                  </b-col>
                  <b-col>
                    <span class="oomSliderDetails">{{slide.tournaments_played}}</span>
                  </b-col>
                </b-row>
              </div>
            </slide>
        </carousel-3d>
      </b-container>
    </div>
</template>
<script>
import apiCall from "@/services/api.js";
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  name: "OOM Slider Homepage",
  props: ["Year", 'config'],
    components: {
        Carousel3d,
        Slide
    },
  data() {
    return {
      slides: 10,
      fields: [
        { key: "pos", label: "Pos" , class: "posnum"},
        { key: "name", label: "Name" },
        { key: "nationality", label: "Nat" },
        { key: "oom_value", label: "Points" },
        { key: "tournaments_played", label: "Tourns" }
      ],
      currentOom: [],
      previousOom: [],
      year: parseInt(this.Year) - 1,
      flag: "flag",
      pPage:4,
      oomData: []
    };
  },
  methods:{
    upDown(){
      if (this.pPage === 4)
      this.pPage = 10
      else if (this.pPage === 10)
      this.pPage = 4
    }
  },
  computed:{
    ooms:function (){
      if (this.currentOom !== null)
      return this.currentOom
      else return this.previousOom
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  async mounted() {
    try {
      var stat = "-ooms-oom-" + process.env.VUE_APP_OOM
      // First call, await for call to return
      let second_call = await apiCall.stat(this.Year, stat);
      this.oomData = second_call.data;
      if (Object.keys(this.oomData).length === 0) {
        let third_call = await apiCall.stat(this.year, stat);
        this.slides = third_call.data.oom.oom_entry.slice(0,10);
        let string = JSON.stringify(this.slides)
        localStorage.setItem("oom", string)
        this.oomData = third_call.data;
      } else {
        this.slides = second_call.data.oom.oom_entry.slice(0,10);
        let string = JSON.stringify(this.slides)
        localStorage.setItem("oom", string)
      }
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep .carousel-3d-slider {
  /* width: 722px!important; */
  width: 693px!important;
}
a.playerProfile:hover {
  text-decoration: none;
}
::v-deep a.prev {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Left-Arrow.png);
  height: 66px !important;
  width: 70px !important;
  z-index: 9999;
  /* position: relative; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 14px;
  left: -6px;
}
::v-deep a.next {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Right-Arrow.png);
  height: 66px !important;
  width: 70px !important;
  z-index: 9999;
  /* position: relative; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 14px;
  right: -5px;
}
::v-deep a.next > span{
  display: none;
}
::v-deep a.prev > span{
  display: none;
}
.lineBreakLine {
  margin-top: 10px;
}
.oomSliderPad {
  padding-left: 40px;
  padding-right: 40px;
}
span.oomSliderDetails {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: bold;
  float: right;
}
span.oomSliderTitle {
  color: #707070;
  font-size: 16px;
  font-weight: 500;
}
.posPosition {
  position: relative;
  top: 30px;
}
.posCenter {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 90px;
  margin: auto;
  /* background-color: #263056; */
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Ellipse-3.png);
  background-repeat: no-repeat;
  height: 90px;
  border-radius: 50px;
  position: relative;
  top: -1.8em;
  background-size: contain;
}
.slideName {
  color: #263056;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  /* margin-top: -0.8em; */
  margin-top: -18px;
  margin-bottom: 30px;
}
.oomImage {
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  background-position: center;
}
::v-deep .carousel-3d-container {
  height: 625px !important;
}
::v-deep .carousel-3d-slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  top: 0;
  border-radius: 50px;
  /* border-color: #000; */
  /* border-color: rgba(0, 0, 0, .4); */
  border-style: solid;
  background-size: cover;
  background-color: #fff;
  display: block;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  height: 625px !important;
  border: 1px solid #e9e9e9;
}
.TitleArea {
  padding-bottom: 30px;
}
.paddingSpace {
  padding: 50px;
}
.Allbuttons {
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #263056;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  float: right;
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
h1.heading.extraPad {
  /* padding: 20px; */
}
.heading{
  font-weight: 600;
  font-size: 26px;
  color: #263056;
}
::v-deep tbody {
  font-size: 1rem;
}
a {
  color: #337ab7!important;
  background-color: transparent;
  font-weight: 500;
}
a:hover{
  text-decoration: underline;
}
::v-deep td.posnum {
  padding: 5px;
  background-color: #263056;
  color: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  text-align: center;
}
::v-deep thead {
  color: white; 
}

::v-deep th {
  width: 15%;
  text-align: left;
  background-color: #263056!important;
  font-weight: 400;
}

::v-deep td {
  text-align: left;
  padding-top: 6px!important;
  padding-bottom: 6px!important;
  font-size: 1rem;
}
::v-deep th:first-child{
  text-align: center;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
::v-deep tr:nth-child(odd) {
  background-color: #dfe1e6;
}
.table{
      margin-bottom: 0;
}
.more{
  background-color: #dfe1e6;
  text-align: center;
}
::v-deep td{
  border-top: 0!important;
}
.flex{
  display: inline-flex;
}
.moreBtn{
  color: #337ab7 !important;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6!important;
  border: none!important;
  box-shadow: none!important;
  padding: 0.375rem 0.175rem;
}
.fullList{
  width: auto!important;
}
.fullList>a{
  color: #337ab7 !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}
li {
  list-style: none;
}
.black{
  color: black;
  margin-left: -10px;
}


@media only screen and (max-width: 990px) {
  .carousel-3d-slide.current {
    width: 100%!important;
  }
  .Allbuttons {
    float: none;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #263056;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    display: block;
    margin: auto;
    margin-top: 30px;
  }
  .heading {
    font-weight: 600;
    font-size: 26px;
    color: #263056;
    text-align: center;
    padding-top: 30px;
  }
   .paddingSpace {
    padding: 15px;    
  }
  ::v-deep .carousel-3d-slider {
    width: auto!important;
  }
}
@media only screen and (max-width: 768px) {

  ::v-deep th {
    font-size: 13px !important;
  }
  ::v-deep td {
    font-size: 13px !important;
  }
  .fullList>a{
    font-size: 12px!important;
    padding-left: 0.7rem;
  }.moreBtn{
    font-size: 12px!important;
  }
  ::v-deep th:nth-child(3){
    display: none;
  }
  ::v-deep td:nth-child(3){
    display: none;
  }
}
</style>

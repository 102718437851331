<template>
    <div>
      <div class="WidgetTop-Section">
        <b-row>
          <b-col lg="2" sm="2">
            <div class="outerBorderImage">
              <div class="outerSponImage">
                <img class="sponsorImage" :src="TourLogo">
              </div>
            </div>
          </b-col>
          <b-col lg="10" sm="10">
            <div class="contentBlock">
              <span class="titleRound">
                <template v-if="currentTitle == 'Live Scoring Monitor'">
                  Latest Scores
                </template>
                <template v-else>
                  {{currentTitle}}
                </template>
              </span>
              <span class="tourName">{{this.TourName}}</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <ReportTable
        :data="data"
        :title="title"
        :home="true"
        :updown="updown"
        :config="config"
        :team='teamEvent'
        :match='match'
      />
      <b-nav small class="fullListings">
        <b-container style="display: contents;">
          <b-nav-item
            v-if="title === 'Tournament Entries'"
            class="Flisting-btn right"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  '~alphaorder~',
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
            ><span class="black"></span> See Full Listing
          </b-nav-item
          >

          <b-nav-item
            v-if="title === 'Final Result'"
            class="Flisting-btn right"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmresult.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  'result=PF~&',
                id: Year,
                code: courseCode,
                title: 'Final Result',
              },
            }"
            ><span class="black"></span> See Full Listing
            </b-nav-item
          >


          <b-nav-item
            v-if="currentReport.includes('draw')"
            class="left drawListing-btn"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Draw',
              },
            }"
            ><span class="black"></span> Draw
          </b-nav-item>
          <!-- <b-nav-item
            v-if="title === 'Draw'"
            class="right drawListing-btn "
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  '~alphaorder~',
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
          >
            <span class="black">|</span> See Full Listing
          </b-nav-item> -->

          <!-- <b-nav-item
            v-if="title === 'Score'"
            class="center three Flisting-btnt"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Score',
              },
            }"
            ><span class="black">|</span> Score
          </b-nav-item>
          <b-nav-item
            v-if="title === 'Score'"
            class="right Flisting-btnt three"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  '~alphaorder~',
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
            ><span class="black">|</span> See Full Listing
          </b-nav-item> -->
          <!-- <b-nav-item
            v-if="title === 'Live'"
            class="center four Flisting-btn"
            :to="{
              name: 'live',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Hole By Hole',
              },
            }"
            ><span class="black">|</span> Hole By Hole
          </b-nav-item> -->
          <b-nav-item
            v-if="currentReport.includes('score')"
            class="right four Flisting-btn"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: title,
              },
            }"
            ><span class="black"></span> See Full Listing
          </b-nav-item>
        </b-container>
      </b-nav>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "homeTourOne",
  components: {
    ReportTable,
  },
  props: [
    "Year",
    "courseCode",
    "TourName",
    "TourLogo",
    "currentReport",
    "currentTitle",
    "reportURL",
    "live",
    "config",
    'match',
    'roundsPLayed'
  ],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      data: [],
      tab: "tab",
      cell: "cell",
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.live === "Y") message = " - Livescoring is on";
      else message = " - " + this.title;
      return message;
    },
    title: function(title) {
      if (this.currentReport.includes("entry")) title = "Tournament Entries";
      else if (this.currentReport.includes("draw")) 
      if (this.roundsPLayed == '0') {
        title = "Round " + '1' + " Draw";
      } else {
       title = "Round " + this.roundsPLayed + " Draw"; 
      }
      else if (this.currentReport.includes("latest")) title = "Round " + this.roundsPLayed +  " Scores Only";
      else if (this.currentReport.includes("scores")) title = "Round " + this.roundsPLayed +  " Scores Only";
      else if (this.currentReport.includes("scoreboard")) title = "Round " + this.roundsPLayed +  " Scoreboard";
      else if (this.currentReport.includes("result")) title = "Final Result";

      return title;
    },
        teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
  },
  methods: {
    updateStuff: function() {
      this.callOne()
    },
    upDown() {
      this.updown = !this.updown;
    },
        callOne() {
      apiCall
        .report(this.currentReport)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  created() {
    setInterval(this.updateStuff, 30000);
  },
  mounted() {
    this.callOne()
  },
};
</script>

<style scoped>
::v-deep .scores-only-table > tbody > tr > td {
  font-size: 14px!important;
}
.outerBorderImage {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  /* border: 19px solid #ff0000; */
  opacity: 0.9;
  /* margin-top: -47px; */
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/White_Circle.png);
  background-repeat: no-repeat;
  background-size: contain;
  
}
.outerSponImage {
  /* background-color: #fff; */
  border-radius: 50%;
  /* height: 230px;
  width: 230px; */
  height: 232px;
  width: 232px;
}
img.sponsorImage {
  width: 35px;
  max-width: 35px;
  position: relative;
  top: 22px;
  left: 10%;
  overflow: hidden;
}
.sponsorImage {
  width: 100%;
}
::v-deep tr:nth-child(even) {
  background-color: #fff!important;
}
.Allbuttons {
  /* float: right; */
  /* width: 244px; */
  width: 264px;
  height: 50px;
  background: transparent;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  /* float: right; */
  /* width: 244px; */
  width: 264px;
  height: 50px;
  background: 263056;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
li.nav-item.Flisting-btn.right {
  width: 100%;
  text-align: center;
  /* padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px; */
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
  margin-top: 15px;
}
.titleRound {
  color: #4E5F9B;
  font-size: 14px;
}
.tourName {
  font-size: 17px;
}
.contentBlock {
  display: grid;
  padding-top: 10px;
  padding-left: 20px;
}
::v-deep .title {
  display: none;
}
::v-deep div#entries {
  /* height: 400px; */
  height: 285px;
  display: block;
  overflow: scroll;
}
::v-deep td.drawName.odd {
  /* width: 30%; */
}
::v-deep .entries .LeftAlign {
  text-align: left;
  padding-left: 20px!important;
}
::v-deep .entries th:first-child {
  text-align: center;
  display: none;
}
::v-deep .entries td.posnum {
  padding: 5px;
  /* padding-top: 4px; */
  padding-top: 7px;
  padding-bottom: 7px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #000;
  font-weight: bold;
  display: none;
}
::v-deep li.drawListing-btn > a {
  color: #337ab7 !important;
  padding-right: 10px;
  font-size: 20px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep li.drawListing-btn > a:hover{
  text-decoration: underline;
}
::v-deep li.drawListing-btn {
  width: auto !important;
}
::v-deep .cell {
  padding-top: 6px;
  padding-bottom: 6px;
}
.Flisting-btn {
  /* width: auto !important; */
}
.Flisting-btn-more {
  color: #337ab7 !important;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.375rem 0.175rem;
}
.Flisting-btn-more:hover {
  border: none;
  text-decoration: none;
}
li.Flisting-btn > a {
  color: #263056 !important;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold !important;
  border: 1px solid #D5D5D5;
  border-radius: 30px;
  /* width: 300px; */
}
li.Flisting-btn > a:hover {
  text-decoration: none;
  background-color: #263056;
  color: #fff!important;
}
ul.nav.fullListings.small {
  background: #fff;
  text-align: center;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.tableRow {
  width: 100%;
}
.link {
  text-align: center;
}
.nav-link {
  padding: 0;
  font-weight: 400 !important;
}
.nav-item {
  padding: 0.375rem 0.175rem;
}
.fields {
  color: white;
  background-color: #263056;
}
.WidgetTop-Section {
  color: white;
  /* background-color: #263056; */
  background-image: linear-gradient(#33406F, #263056);
  padding: 20px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.topTab {
  padding: 0;
}
.black {
  color: black;
}
::v-deep thead.HomeHide {
  display: none;
}
@media only screen and (max-width: 990px) {
  .outerBorderImage {
    margin: auto;
  }
  .WidgetTop-Section {
    text-align: center;
  }
  img.sponsorImage {
    width: 50px;
    max-width: 60px;
    position: relative;
    top: 19px;
    left: -75px;
  }
}
@media only screen and (max-width: 768px) {
  .Flisting-btn > a {
    font-size: 12px !important;
  }
  .Flisting-btn-more {
    font-size: 12px !important;
  }
}
</style>
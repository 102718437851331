<template>
  <div class="menu">
    <template v-if="isMobile(true)">
    <div class="mobileBk">
      <b-link to="/">
        <img class="mobLogo" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2023/03/websitelogo_white_stickynav.png" />
      </b-link>

      <div id="nav">
        <b-navbar type="dark" toggleable="xl" id="topNavMob" align="center">
          <b-navbar-toggle
            class="ml-auto"
            type="dark"
            target="nav-collapse"
          ></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-nav-item to="/">Home</b-nav-item>
            <b-nav-item to="/schedule">Schedule</b-nav-item>
            <b-nav-item to="/players">Players</b-nav-item>
            <!-- <b-nav-item to="/playerarea">PlayerArea</b-nav-item> -->
            <b-nav-item to="/news">News</b-nav-item>
            <b-nav-item to="/video">Video</b-nav-item>
            <b-nav-item to="/stats">Stats</b-nav-item>
            <b-nav-item v-if="allow_qschool == 'B' || allow_qschool == 'W'" to="/q-school">Q-School</b-nav-item>
          </b-collapse>
        </b-navbar>
      </div>   
    </div>
    </template>
    <template v-else>
      <div class="topNav">
        <b-container>
          <b-row>
            <b-col>
              <b-link to="/" class="noLines">
                <img class="alpsLogo" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/ALPS-TOUR-NEGATIVE_Logo-1.png">
              </b-link>
            </b-col>
            <b-col>
              <div class="RightNav">
                <b-nav-item class='headerNavLink' to="/about">About Us</b-nav-item>
                <b-nav-item class='headerNavLink' to="/contact">Contact Us</b-nav-item>
                <div class="socialROw">
                  <b-row>
                    <b-col>
                      <b-link href="https://www.youtube.com/channel/UC88jp_VEEHXDJzDjAA2lqwg" target="_blank" class="linkClass">
                        <img class="socialIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/You-tube.png">
                      </b-link>
                    </b-col>
                    <b-col>
                      <b-link href="https://www.facebook.com/alpstourgolf" target="_blank" class="linkClass">
                        <img class="socialIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Facebook.png">
                      </b-link>
                    </b-col>
                    <b-col>
                      <b-link href="https://www.instagram.com/alpstourgolf/" target="_blank" class="linkClass">
                        <img class="socialIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Instagram.png">
                      </b-link>
                    </b-col>
                    <b-col>
                      <b-link href="https://x.com/i/flow/login?redirect_after_login=%2Falpstourgolf" target="_blank" class="linkClass">
                        <img class="socialIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Twitter-X.png">
                      </b-link>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-row class="secondNav">
          <b-col cols="8" class="colTwel">
            <div id="nav">
              <b-navbar type="dark" toggleable="xl" id="topNav" align="center">
                <b-navbar-toggle
                  class="ml-auto"
                  type="dark"
                  target="nav-collapse"
                ></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                  <b-nav-item to="/">Home</b-nav-item>
                  <b-nav-item to="/schedule">Schedule</b-nav-item>
                  <b-nav-item @click="myFunctionPlayers()">Players</b-nav-item>
                  <b-nav-item @click="myFunctionMedia()">Media</b-nav-item>
                  <b-nav-item to="/stats">Stats</b-nav-item>
                  <b-nav-item v-if="allow_qschool == 'B' || allow_qschool == 'W'" to="/q-school">Q-School</b-nav-item>
                </b-collapse>
              </b-navbar>
            </div>         
          </b-col>
          <b-col cols="4">
            <div id="socialNav">

              <div style="display:inline-flex">
                <!-- <b-form-input
                  v-model="text"
                  @keyup.enter="goSearch()"
                  placeholder="Search"
                ></b-form-input>
                <b-button
                  :to="{
                    name: 'search',
                    query: { search: text },
                  }"
                  class="sIcon"
                >
                  <font-awesome-icon :icon="['fa', 'search']" />
                </b-button> -->
                  <!-- <input type="text" name="search" placeholder="Search.."  v-model="text" @keyup.enter="goSearch()"> -->
                    <div class="box">
                        <!-- <form name="search"> -->
                            <input type="text" class="input" name="search"  @keyup.enter="goSearch()" v-model="text">
                        <!-- </form> -->
                        <font-awesome-icon class="magGlassIcon" :icon="['fa', 'search']" />
                    </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div id="myDIV" class="players">
        <b-container>
          <div class="players">
            <b-row>
              <b-col lg="4">
                <div class="playerSectionBlock">
                  <div class="playersSecLine">
                    <b-link class="linksPlayers" to="/players" @click="hide('myDIV')">Player Profiles</b-link>
                  </div>
                  <!-- <div class="playersSecLine">
                    <b-link class="linksPlayers" to="/playerarea"  @click="hide('myDIV')">Players Area, Entries & Exemptions</b-link>
                  </div> -->
                  <div class="playersSecLine">
                    <b-link class="linksPlayers" target="_blank" href="https://ocs-ffg.com/ffg-members/"  @click="hide('myDIV')">Secure Member’s Area</b-link>
                  </div>
                </div>
              </b-col>
              <b-col lg="8">
                <b-row>
                  <b-col v-for="(oom, index) in oomList.slice(0,3)" :key="index">
                    <div class="oomWholeSection">
                      <div class="playerBackground" v-bind:style="{ 'background-image': 'url(' + 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' + oom.playerno + '.jpg' + ')' }">
                      </div>
                      <div class="oomPos" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Ellipse-3.png' + ')'}" >
                        <div class="oomPosition">
                          {{oom.pos}}
                        </div>
                      </div>
                      <div class="nameSection one">
                        <b-link :to="'/playerprofile/' + oom.playerno"  @click="hide('myDIV')">
                          {{oom.name}}
                        </b-link>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
      <div id="myDIVTwo" class="media">
        <b-container>
          <div class="media">
            <b-row>
              <b-col lg="4">
                <div class="mediaSectionBlock">
                  <div class="playersSecLine">
                    <b-link class="linksPlayers" to="/news" @click="hide('myDIVTwo')">News</b-link>
                  </div>
                  <div class="playersSecLine">
                    <b-link class="linksPlayers" to="/video"  @click="hide('myDIVTwo')">Videos</b-link>
                  </div>
                  <div class="playersSecLine">
                    <b-link class="linksPlayers" to="/photo"  @click="hide('myDIVTwo')">Photos</b-link>
                  </div>
                </div>
              </b-col>
              <b-col lg="8">
                <b-row>
                  <b-col v-for="(art, index) in posts.slice(0,1)" :key="index" lg="6" sm="12">
                    <div class="oomWholeSectionMedia">
                      <div class="playerBackground" v-bind:style="{ 'background-image': 'url(' + art.better_featured_image.source_url + ')' }">
                      </div>
                      <div class="nameSection">
                        <b-link :to="'/news/' + art.id"  @click="hide('myDIV')">
                          {{art.title.rendered}}
                        </b-link>
                      </div>
                    </div>
                  </b-col>
                  <b-col v-for="(vid, index) in video.slice(0,1)" :key="index" lg="6"  sm="12">
                    <div class="oomWholeSectionMedia">
                      <b-embed
                        type="iframe"
                        aspect="16by9"
                        :src="
                          'https://www.youtube.com/embed/' +
                            vid.contentDetails.videoId
                        "
                        allowfullscreen
                      ></b-embed>
                      <div class="nameSection">
                          {{vid.snippet.title}}
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import axios from 'axios';
export default {
  name: "Menu",
  props: ["config"],
  data() {
    return {
      menueLink: "menuLink",
      text: "",
      showModal: false,
      allow_qschool:"",
      oomList: [],
      posts: [],
      video: []
    };
  },
  created() {
    apiCall
    .tmParamsBase()
    .then(({ data }) => {
      this.allow_qschool = data.tm_params.allow_qschool;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      sessionStorage.setItem("showModal", "yes");
    } else {
      sessionStorage.setItem("showModal", "no");
    }
  },
  mounted() {
    this.myFunctionPlayers();
    this.myFunctionMedia();
    window.addEventListener('mouseup',function(event){
          var pol = document.getElementById('myDIV');
          if(event.target != pol && event.target.parentNode != pol){
              pol.style.display = 'none';
          }
    });  
    window.addEventListener('mouseup',function(event){
          var pol = document.getElementById('myDIVTwo');
          if(event.target != pol && event.target.parentNode != pol){
              pol.style.display = 'none';
          }
    }); 
    window.addEventListener('mouseup',function(event){
          var pol = document.getElementById('myDIVThree');
          if(event.target != pol && event.target.parentNode != pol){
              pol.style.display = 'none';
          }
    }); 
    const showModal = sessionStorage.getItem("showModal");
    console.log(showModal);
    if (showModal === "yes") setTimeout(() => this.$refs.myModal.show(), 3500);
    let retString = localStorage.getItem("oom")
    let retArray = JSON.parse(retString)
    this.oomList = retArray;
    console.log("retArray");
    console.log(retArray);
    this.$bvModal.hide('myDIV')
    this.menuNews();
    this.getYoutube();
  },
  methods: {
    hide: function(obj) {
      var el = document.getElementById(obj);
      el.style.display = 'none';
    },
    myFunctionPlayers() {
      var v = document.getElementById("myDIV");
      var m = document.getElementById("myDIVTwo");
        if (v.style.display === "none") {
            v.style.display = "block";
            m.style.display = "none";
        } else {
            v.style.display = "none";
        }
    },
    myFunctionMedia() {
      var v = document.getElementById("myDIVTwo");
      var p = document.getElementById("myDIV");
        if (v.style.display === "none") {
            v.style.display = "block";
            p.style.display = "none";
        } else {
            v.style.display = "none";
        }
    },
    goSearch() {
      console.log('Go search')
      // this.text = text
      this.$router.push({ name: "search", query: { search: this.text } });
    },
    show() {
      this.showModal = true;
      this.$emit("show-me");
    },
    close() {
      this.showModal = false;
      sessionStorage.setItem("showModal", "no");
      this.$emit("hide-me");
    },
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
    menuNews() {
      const json = "wp/v2/posts?randomadd=" +
            new Date().getTime();
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.posts = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    getYoutube: function() {
      axios.get(
        'https://api.euro.ocs-software.com/youtube/videos?playlistId=' + 'UC88jp_VEEHXDJzDjAA2lqwg' + '&key=' + 'AIzaSyAmgpLKnjwQS0eueW1RRmGeEpXNMHXgdKg' + '&maxResults=10&type=video&order=date&part=snippet,contentDetails&'
      )
      .then(({ data }) => {
        this.video = data.items;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
  },
  computed: {
    extension: function (extension){
      var I = 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg';
      // var P = 'https://ocs-ffg.com/pmws-images/ffg/logos/websitelogo.png';
      if(I.includes('.jpg')){
        extension = 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg';
      }
      else {
        extension = 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg';
      }
      return extension
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.socialROw {
  display: inline-block;
}
button.navbar-toggler.ml-auto.collapsed {
  margin-top: -5.5em;
}
button.navbar-toggler.ml-auto.not-collapsed {
  margin-top: -5.5em;
}
#topNavMob {
  /* position: absolute;
  right: 20px;
  top: 8px; */
}
img.mobLogo {
  width: 300px;
  padding-top: 18px;
  padding-bottom: 18px;
}
.mobileBk {
  background-color: #263056;
}
::v-deep .box{
  position: relative;
  top: -10px;
}
svg.magGlassIcon.svg-inline--fa.fa-search.fa-w-16 {
  position: absolute;
  top: 13px;
  left: 13px;
}
::v-deep .input {
  padding: 10px;
  width: 45px;
  height: 45px;
  background: none;
  border: 2px solid #263057;
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 20px;
  color: #263057;
  outline: none;
  transition: .5s;
  text-indent: 25px;
}
::v-deep .box:hover input{
  width: 350px;
  background: #fff;
  border-radius: 10px;
}
::v-deep .box i{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%,-50%);
  font-size: 26px;
  color: #263057;
  transition: .2s;
}
::v-deep .box:hover i{
  opacity: 0;
  z-index: -1;
}
/* input::before {
  content:url('https://ams3.digitaloceanspaces.com/ocs-sport/alpstourgolf/2025/02/Search-Icon-1.png');
} */
::v-deep .embed-responsive-16by9::before {
  padding-top: 49.25%;
}
::v-deep .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.nameSection {
  padding-top: 30px!important;
  height: 170px;
}
.nameSection.one {
  padding-top: 30px!important;
  height: auto;
}
.router-link-exact-active {
  background-color: transparent!important;
}
.oomPos {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  z-index: 999;
  left: 37%;
  right: 37%;
  margin-top: -50px;
  width: 80px;
  height: 80px;
}
.oomPosition {
  position: relative;
  left: 33px;
  /* right: 0; */
  top: 23px;
  font-size: 16pt;
}
.oomWholeSection {
  margin-top: 3em;
}
.oomWholeSectionMedia {
  margin-top: 3em;
  padding-bottom: 3em;
}
.playerBackground {
  height: 230px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.nameSection {
  background-color: #fff;
  padding: 15px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #263056;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding-top: 38px;
  padding-bottom: 30px;
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameSection > a {
  color: #263056!important;
}
.playerSectionBlock {
  margin-top: 7em;
}
.mediaSectionBlock {
  margin-top: 7em;
  text-align: left;
}
.playersSecLine {
  padding-top: 10px;
  padding-bottom: 10px;
}
a.linksPlayers {
  color: #fff!important;
  font-size: 18px;
}
a.linksPlayers:hover {
  color: #4E5F9B!important;
  text-decoration: none;
  font-size: 18px;
}
.players {
  background-color: #0A0F24;
  color: #fff;
  z-index: 999;
  position: absolute;
  height: 456px;
  width: 100%;
  display: contents;
}
.media {
  background-color: #0A0F24;
  color: #fff;
  z-index: 999;
  position: absolute;
  width: 100%;
  display: contents;
  height: 550px;
}
a.noLines.router-link-exact-active.router-link-active {
  background-color: transparent !important;
}
li.nav-item.headerNavLink {
  position: relative;
  right: 5em;
}
img.socialIcon {
  /* width: 15px;
  height: 15px; */
  width: 20px;
  height: 18px;
}
.secondNav {
  padding-top: 20px;
  padding-bottom: 20px;
}
::v-deep .headerNavLink > a {
  color: #fff!important;
}
::v-deep .headerNavLink > a:hover {
  color: #4E5F9B!important;
}
.RightNav {
  text-align: right;
  position: relative;
  top: 25px;
}
img.alpsLogo {
  width: 300px;
  margin-top: 5px;
}
.topNav {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Header-Bar.png);
  height: 100px;
  background-repeat: repeat;
}
#mobileLogo {
  position: absolute;
  z-index: 99;
  /* margin-top: 13px; */
  width: 250px;
  max-width: 510px;
  left: 0;
  right: 0;
  margin: auto;
  top: 15px;
  display: none;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
#socialNav {
  text-align: right;
  position: relative;
  top: 11px;
}

::v-deep .navbar {
  padding: 0px;
}

li.nav-item {
  display: inline-block;
}
::v-deep .nav-link {
  color: white !important;
  padding: 0.7em 1rem;
}

::v-deep .navbar {
  padding-bottom: 0;
}

::v-deep .navbar-toggler {
  border: 0px;
}

::v-deep .nav-link:hover {
  color: #263056 !important;
}
.menuS > .nav-link:hover {
  background-color: transparent !important;
}
.menuS > .nav-link {
  padding: 0;
}
.menuS {
  padding: 0 2px;
}
::v-deep .dropdown-item {
  color: #263056 !important;
  /* background-color: #263056; */
}

::v-deep .dropdown-menu {
  color: #263056 !important;
  /* background-color: #263056; */
}

::v-deep .dropdown-item:hover {
  color: #263056 !important;
  background-color: white;
}

#logo {
  max-width: 510px;
  padding-top: 1%;
}
.logoWrap {
  text-align: center;
}
.icon {
  font-size: 25pt;
}

#fb {
  color: #3b5998;
}

.fa-gradient {
  color: #4c68d7;
}

#twit {
  color: #1da1f2;
}

#yt {
  color: #ff0000;
}

#topNav {
  background-color: #fff;
}

::v-deep .nav-link {
  color: #707070 !important;
  padding: 0.7em 1rem;
  /* font-size: 18px; */
  font-size: 16px;
}

.menu {
  padding-bottom: 0%;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  z-index: 999;
}

.router-link-exact-active {
  background-color: white;
  color: #263056 !important;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
::v-deep .modal-content {
  border-radius: 17px;
}
::v-deep .modal-body {
  text-align: center;
  background-color: #263056;
  color: white;
  border-radius: 15px;
}
.appStore {
  padding: 20px;
}
.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
::v-deep .dropdown-toggle::after {
  vertical-align: 0.2em;
  font-size: 15px;
}
.navbar-collapse {
  text-align: center;
}
::v-deep .navbar-toggler > span {
  cursor: pointer;
  border-radius: 0px;
  height: 2px;
  width: 35px;
  background: white;
  position: absolute;
  display: block;
  content: "";
  right: 20px;
  transition: all 200ms ease-in-out;
}
::v-deep .not-collapsed > span {
  background-color: transparent;
}
::v-deep .not-collapsed  > span::before {
transform: rotate(-45deg);
    top: 0!important;
}
::v-deep .not-collapsed  > span::after {
    transform: rotate(45deg);
        top: 0;
}
::v-deep .navbar-toggler > span::before {
  cursor: pointer;
  border-radius: 0px;
  height: 2px;
  width: 35px;
  background: white;
  position: absolute;
  display: block;
  content: "";
  top: -10px;
  transition: all 200ms ease-in-out;
}
::v-deep .navbar-toggler > span::after {
  cursor: pointer;
  border-radius: 0px;
  height: 2px;
  width: 35px;
  background: white;
  position: absolute;
  display: block;
  content: "";
  bottom: -10px;
  transition: all 200ms ease-in-out;
}

@media only screen and (min-width: 1200px) {
  .searchMobile {
    display: none!important;
  }
}

@media only screen and (max-width: 1199px) {
  .colTwel.col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  button.navbar-toggler.ml-auto.collapsed {
    margin-top: -6.5em;
  }
  button.navbar-toggler.ml-auto.not-collapsed{
    margin-top: -6.5em;
  }
  ::v-deep .RightNav {
    text-align: right;
    position: relative;
    top: 25px;
    display: none;
  }
  .searchMobile {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 80%;
  }
  #socialNav {
    display: none;
  }
  .navbar-toggler {
    padding: 40px;
  }
  .navbar-collapse>.nav-item{
    display: flow-root;
    padding-bottom: 20px;
  }
  ::v-deep .dropdown-menu {
    position: relative!important;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: center;
    list-style: none;
    background-color: #1d2441;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin: auto;
  }
  ::v-deep .dropdown-item {
    color: white !important;
    background-color: #1d2441;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .nav-link {
    color: #fff !important;
    padding: 0.7em 1rem;
    /* font-size: 18px; */
    font-size: 16px;
  }
}
@media only screen and (max-width: 980px) {
  .router-link-exact-active{
    background-color: white;
    color: #f8f8f8 !important;
  }
  #topNav {
    display: none;
  }
  .logoWrap {
    text-align: left;
  }
  .RightNav {
    display: none;
  }
  #topNav {
    background-color: transparent;
  }
  .secondNav {
    padding-top: 20px;
    padding-bottom: 20px;
    display: none;
  }
}
@media only screen and (max-width: 840px) {
  .logoWrap {
    text-align: center;
  }
}
@media only screen and (min-width: 760px) {
  #mobileLogo {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  #logo {
    width: 60%;
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  #nav-collapse {
    padding-left: 0;
  }
  #socialNav {
    position: inherit;
    width: 100%;
    padding: 20px;
  }
  ::v-deep .dropdown-item {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1200px) {
  ::v-deep .navbar-expand-xl .navbar-collapse {
    display: inline-table !important;
    text-align: left;
  }
}
</style>
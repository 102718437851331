<template>

  <div>
    <b-container>
      <b-row class="TitleArea">
          <b-col sm="12" lg="6">
              <h1 class="heading extraPad">Upcoming Alps Tour Tournaments</h1>
          </b-col>
          <b-col sm="12" lg="6">
              <b-link to="/schedule" class="allNewsLink">
                  <button class="Allbuttons">
                      See Full Schedule
                  </button>
              </b-link>
          </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="4" class="upcomingTournaments" v-for="(upComingTours, index) in FutureEventFilter.slice(0, 3)" :key="index" >
          <b-link :to="'/tournament' + '/' + upComingTours.code + '/' + Year">
            <div class="upcomingBlock" v-bind:style="{ 'background-image': 'url(' + 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/courses/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1 + ')'}">
              <div class="textBlock">
                <span class="datesUpcoming">
                  {{upComingTours.dates}}
                </span>
                <span class="nameUpcoming">
                  {{upComingTours.short_name}}
                </span>
              </div>
            </div>
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Upcoming Tournaments",
  props: ["Year", "config", "DEFIMAGESAMS3"],
  data() {
    return {
      sliding: false,
      imgURL: this.config.VUE_APP_COURSE_URL,
      file: "File_1.jpg",
      upComingTours: [],
      img: this.config.VUE_APP_DEFAULT_COURSE,
    };
  },
  computed: {
    FutureEventFilter: function() {
      return this.upComingTours.filter(
        (upComingTours) => upComingTours.days_away > 3
      );
    },
  slidesTotal:function(){
    return this.FutureEventFilter.length
  },
},
  methods: {
    replaceByDefault(e) {
      e.target.src = this.img;
    },
  },
    created() {
    apiCall
      .upComing(this.Year)
      .then(({ data }) => {
        this.upComingTours = data.tournaments.tournaments_entry;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.textBlock {
  display: inline-grid;
  position: absolute;
  bottom: 45px;
  left: 40px;
}
.datesUpcoming {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  top: -30px;
}
.nameUpcoming {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  padding-right: 15px;
}
.upcomingBlock {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  /* margin-right: 20px; */
  mix-blend-mode: multiply;
  background-color: black;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73));
}
.upcomingBlock:before {
  content: "";
  position: absolute;
  overflow: hidden;
  /* border-radius: 50px; */
  width: 94%;
  height: 50%;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.TitleArea {
  padding-bottom: 30px;
}
.paddingSpace {
  padding: 50px;
}
.Allbuttons {
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #263056;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  float: right;
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
h1.heading.extraPad {
  /* padding: 20px; */
}
.heading{
  font-weight: 600;
  font-size: 26px;
  color: #263056;
}
::v-deep .bslide > .slick-list > .slick-track > .slick-slide {
  width: 100%!important;
  display: contents;
}
.sponLogo {
  /* background: #fff; */
  /* padding: 40px; */
  /* margin-right: 20px; */
}
p.moreInfo-btn.tournlink {
  background-color: #263056;
  width: 200px;
  text-align: center;
  color: #fff;
  /* border-radius: 10px; */
  padding: 0.5rem 1rem;
}
::v-deep .bslide > .slick-list > .slick-track > .slick-slide {
  width: 100%!important;
}
::v-deep .bslide > .slick-list > .slick-track{
  display: flex;
  overflow: scroll;
  width: 100%!important;
  height: inherit;
}
::v-deep a {
  color: black;
}
img.logo {
  max-width: 136px;
  float: right;
  padding-right: 45px;
  margin-top: -100px;
}

.courseImg {
  width: 100%;
}
::v-deep .slick-slider > button {
  display: none !important;
}
.backImg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 415px !important;
}
.minibackImg {
  background-color: lightgrey;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 76px;
}
::v-deep .slick-slide > div > div > img {
  opacity: 0.5;
}
::v-deep .slick-current > div > div > img {
  opacity: 1;
}
.cap {
  position: absolute;
  color: white;
  padding-left: 4%;
  text-align: left;
  color: #ffffff;
  background-color: rgba(38, 48, 86, 0.7);
  top: -300px;
  padding-top: 20px;
  height: 225px;
  left: 15px;
  right: 58%;
}
::v-deep .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  transform: translateZ(0);
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
}
::v-deep .bslide > .slick-list {
  height: 150px;
}

.tourName {
  font-size: 14px;
  color: #263056;
  margin-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.tourNameTwo {
  color: #d3d3d3;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.mobile {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .upcomingBlock:before {
    content: "";
    position: absolute;
    overflow: hidden;
    /* border-radius: 50px; */
    width: 91%;
    height: 50%;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}
@media only screen and (max-width: 1281px) {
  img.logo {
    max-width: 136px;
    float: right;
    padding-right: 45px;
    margin-top: -100px;
  }
}
@media only screen and (max-width: 900px) {
  .upcomingTournaments {
    padding-top: 15px;
  }
  #slider {
    display: none;
  }
  .mobile {
    display: block;
    padding: 20px 0;
  }
  ::v-deep .mobCap > .carousel-caption {
    bottom: 0px;
    background-color: rgba(38, 48, 86, 0.7);
    margin: auto;
  }
  ::v-deep .img-fluid {
    height: 180px;
  }
  ::v-deep .mobCap > .carousel-caption > a{
    color:#fff!important;
  }
  .heading {
    font-weight: 600;
    font-size: 26px;
    color: #263056;
    text-align: center;
    margin-top: 30px;
  }
  .Allbuttons {
    float: none;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #263056;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    margin: auto;
    display: block;
    margin-top: 30px;
  }
  .Allbuttons:hover {
    float: none;
    width: 244px;
    height: 50px;
    background: #263056;
    color: #fff;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    margin: auto;
    display: block;
    margin-top: 30px;
  }
  /* .Allbuttons {
    float: none;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #263056;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    margin: auto;
    display: block;
    margin-top: 30px;
  } */
}
@media only screen and (max-width: 500px) {
  ::v-deep .img-fluid {
    height: 130px;
  }
  ::v-deep .mobCap > .carousel-caption{
    bottom:10px;
  }
  .upcomingBlock:before {
    content: "";
    position: absolute;
    overflow: hidden;
    /* border-radius: 50px; */
    width: 94%!important;
    height: 50%;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}
</style>

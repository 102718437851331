<template>
  <div>
    <template v-if="$route.name != 'tournament' && $route.name != 'playerprofile' ">
      <b-row>
        <b-col sm="12" lg="6">
          <h1 class="heading">Latest Alps Tour Golf News</h1>
        </b-col>
        <b-col sm="12" lg="6">
          <b-link to="/news" class="allNewsLink">
            <button class="Allbuttons">
              See More News
            </button>
          </b-link>
        </b-col>
      </b-row>
      <b-row :class="postsRow">
        <b-col
          lg="6"
          id="mobilePost"
          v-for="(posts, index) in posts.slice(0, 1)"
          :key="index"
        >
          <b-nav-item :to="'/news/' + posts.id" class="NewsArtileOne">
            <div class="overflowImage">
              <b-img
                :class="postImg"
                :src="
                    posts.better_featured_image.source_url
                "
                onerror="this.onerror=null;this.src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Header-Bar.png';"
                fluid
                alt="Responsive image"
              />
            </div>
            <div class="FirstPostContentBlock">
              <p class="date">{{dateTime}}</p>
              <h1 class="postsTitle LeftImage">
                <span v-html="posts.title.rendered"></span>
              </h1>
            </div>
            <!-- <span class="postText" v-html="posts.excerpt.rendered"></span> -->
          </b-nav-item>
        </b-col>
        <b-col
        >
          <b-row>
            <b-col 
              lg="12"
              id="mobilePost"
              v-for="(posts, index) in posts.slice(1, 4)"
              :key="index">
                <b-nav-item :to="'/news/' + posts.id">
                  <b-row>
                    <b-col lg="8">
                      <p class="date">{{dateTime}}</p>
                      <h1 class="postsTitle rightTitles">
                        <span v-html="posts.title.rendered"></span>
                      </h1>
                    </b-col>
                    <b-col>
                      <div class="overflowImage newsSmallImage" v-bind:style="{ 'background-image': 'url(' +
                              posts.better_featured_image.source_url + ')' }">
                      </div>
                    </b-col>
                  </b-row>
                  <!-- <span class="postText" v-html="posts.excerpt.rendered"></span> -->
                </b-nav-item>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row :class="postsRow">
        <b-col
          lg="6"
          id="mobilePost"
          v-for="(posts, index) in posts.slice(0, 1)"
          :key="index"
        >
          <b-nav-item :to="'/news/' + posts.id">
            <div class="overflowImage">
              <b-img
                :class="postImg"
                :src="
                  posts.better_featured_image.source_url
                "
                fluid
                alt="Responsive image"
              />
            </div>
            <div class="FirstPostContentBlock">
              <p class="date">{{dateTime}}</p>
              <h1 class="postsTitle LeftImage">
                <span v-html="posts.title.rendered"></span>
              </h1>
            </div>
          </b-nav-item>
        </b-col>
        <b-col
        >
          <b-row>
            <b-col 
              lg="12"
              id="mobilePost"
              v-for="(posts, index) in posts.slice(1, 4)"
              :key="index">
                <b-nav-item :to="'/news/' + posts.id">
                  <b-row>
                    <b-col lg="8">
                      <p class="date">{{dateTime}}</p>
                      <h1 class="postsTitle rightTitles">
                        <span v-html="posts.title.rendered"></span>
                      </h1>
                    </b-col>
                    <b-col>
                      <div class="overflowImage newsSmallImage" v-bind:style="{ 'background-image': 'url(' + posts.better_featured_image.source_url + ')'}">
                      </div>
                    </b-col>
                  </b-row>
                </b-nav-item>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import axios from 'axios';
export default {
  name: "posts",
  props: ["config", 'tag', "course_code", "Year", 'count', 'featuredTrue'],
  data() {
    return {
      posts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  computed: {
    dateTime:function(){
      var dateOG = this.posts[0].date.split('T')
      var splitDate = dateOG[0].split('-')
      var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
      // var time = dateOG[1]
      return 'Published ' + newDate

    }
  },
  methods: {
    tournamentNews: function () {
    if (this.$route.name === "tournament" || this.$route.name === "playerprofile")
      axios //add API Call
        .get( 'https://wp-alpstour.ocs-sport.com/wp-json/wp/v2/' + "posts?tags=" + this.tag + "&randomadd=" + new Date().getTime())
        .then((response) => (this.posts = response.data));
    }
  },
  created() {
    const json = "wp/v2/posts";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  mounted() {
    this.tournamentNews();
  }
};
</script>

<style scoped>
li.nav-item.NewsArtileOne > a {
  padding-left: 0px;
  /* padding-right: 0px; */
}
.overflowImage.newsSmallImage {
  height: 190px;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.Allbuttons {
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #263056;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  float: right;
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
}
.heading{
  font-weight: 600;
  font-size: 26px;
  color: #263056;
}
.FirstPostContentBlock {
  border: 1px solid #E8E8E8;
  width: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 35px;
  height: 250px;
}
p.date {
  color: #B2B2B2;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}
h1.postsTitle.LeftImage {
  font-weight: 600;
}
h1.postsTitle.rightTitles {
  font-size: 26px;
}
h1.postsTitle.rightTitles > span {
  font-size: 26px;
}
img.imageRight.img-fluid.postImg {
  border-radius: 50px;
  max-width: 100%;
  height: 188px;
  width: auto;
  float: right;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  font-size: 32px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* white-space: nowrap; */
  /* width: 350px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  color: #263056;
  font-weight: 600;
}
.overflowImage {
  overflow: hidden;
  /* height: 278px; */
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}
#mobilePost:last-child {
  /* padding-right: 0px; */
}

.postRow {
  padding-bottom: 10px;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
  text-align: left;
}
.moreNews>a{
  background-color: transparent;
  color: #263056!important;
}
#leftMore{
  color: #fff;
  background-color: #263056;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  padding: 3px;
}
@media only screen and (max-width: 480px) {
  ::v-deep .nav-link {
    width: 100%;
  }
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
}
@media only screen and (max-width: 990px) {
  .overflowImage.newsSmallImage {
    height: 190px;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .FirstPostContentBlock {
    height: auto;
  }
  .Allbuttons {
    float: none;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #263056;
    font-weight: 500;
    border-radius: 50px;
    border: 1px solid #E8E8E8;
    margin-top: 30px !important;
    text-align: center;
    margin: auto;
    display: block;
  }
  .Allbuttons:hover {
    float: none;
    width: 244px;
    height: 50px;
    background: #263056;
    color: #fff;
    font-weight: 500;
    border-radius: 50px;
    border: 1px solid #E8E8E8;
    margin-top: 30px !important;
    text-align: center;
    margin: auto;
    display: block;
  }
  .heading {
    font-weight: 600;
    font-size: 26px;
    color: #263056;
    text-align: center;
  }
}
@media only screen and (max-width: 1165px) {
  #mobilePost:last-child {
    padding-right: 15px;
    margin-top: 2em;
  }
  div#mobilePost {
    margin-top: 1em;
  }
}
</style>
